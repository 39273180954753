import React from 'react'

const Footer = () => {
  return (
    <div className='bg-footer-section  bg-cover bg-center w-full lg:h-[400px] lg:mt-[100px] mt-[50px]'>
      <main className='lg:flex lg:justify-center lg:gap-[160px] lg:pt-[85px] pt-10'>
        <div><img src="/homeimage/homeimage2.png" alt="" className='h-[40px] mx-auto lg:mx-0' />
        <p className='mt-[30px] lg:w-[400px] text-white text-center lg:text-start'>Binary Hub - Your Partner in Digital
Transformation. Empowering businesses with
innovative IT solutions.</p></div>

{/* services section is here */}
<div className='mt-8 lg:mt-0' >
    <h3 className='text-white font-semibold text-[20px] leading-[28px] text-center lg:text-start'>Services</h3>
    <ul className='list-none mt-5 '>
        <li className='text-white   mt-2 text-center lg:text-start' >
        Web developments
        </li>
        <li className='text-white mt-2 text-center lg:text-start'  >UI/UX Design</li>
        <li  className='text-white mt-2 text-center lg:text-start' >Digital Marketing</li>
        <li className='text-white mt-2 text-center lg:text-start' >Graphic Design</li>
        </ul>
</div>

<div className='mt-8 lg:mt-0'>
    <h3 className='text-white  font-semibold text-[20px] leading-[28px] text-center lg:text-start  '>Contact Us</h3>
    <ul className='list-none mt-5'>
        <li className='mt-2 text-center lg:text-start'>
       <i className="fa-solid fa-envelope text-white" ></i>
       <span className='ml-3 text-white'>xxx @gmail.com </span>
        </li>
        <li className='mt-2 text-center lg:text-start'>
            <i className="fa-solid fa-location-crosshairs text-white" ></i>
            <span className='ml-3 text-white'>Gilgil, pakistan</span>
        </li>
        
        </ul>
</div>
      </main>
    </div>
  )
}

export default Footer;

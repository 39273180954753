import React from 'react'
import { motion } from 'framer-motion'



const Navbar = () => {
  return (
    <div className='app'>
        <motion.header
      className="flex lg:justify-center lg:items-center gap-[500px] lg:px-[100px] lg:pt-5"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2}}
    >
      {/* Logo Animation */}
      <div>
        <img src="/homeimage/homeimage2.png" alt="" className='pt-4 ml-3 w-[200px] lg:w-[400px] ' />
      </div>
      {/* <motion.div
        initial={{ opacity: 0, scaleY: 0.5 }}
        animate={{ opacity: 1, scaleY: 1 }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        <img
          src="/homeimage/homeimage2.png"
                   alt="Home"
        />
      </motion.div> */}

      {/* Navbar Animation */}
      <motion.ul
        className="lg:inline-flex lg:items-center lg:gap-[61px] w-[100%] text-white absolute left-[-900px] bg-black lg:bg-transparent lg:relative lg:left-0"
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
      >
        {["Home", "Portfolio", "Services", "FAQ's"].map((item, index) => (
          <motion.a
            key={item}
            href={item.toUpperCase()}
            className="hover:text-black transition-all duration-500"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.6 + index * 0.2 }}
          >
            {item}
          </motion.a>
        ))}
      </motion.ul>
    </motion.header>
 {/* home section start */}
<motion.section
initial={{ opacity: 0, y: -50 }}
animate={{ opacity: 1, y: 0 }}
transition={{ duration: 0.2}}
 className='lg:flex lg:justify-between lg:items-center lg:gap-[300px] lg:px-[100px] py-[40px] mx-auto  lg:mx-0'>
<div className='py-5'>
  <h1 className='font-inter font-bold text-[40px] leading-[40px] w-[245px]  text-white lg:text-[66px]
  lg:leading-[62px] lg:w-[575px] text-center mx-auto  lg:ml-0 lg:text-start'>Solutions for your Digital needs</h1>

  <p className='lg:w-[533px] lg:font-normal lg:text-[16px] lg:leading-[30px] lg:py-5 font-inter font-normal text-[16px] leading-[24px] text-gray-500 w-[270px]  py-5 lg:ml-0 text-center lg:text-start mx-auto  lg:mt-5'>Binary digital service- a venture of binary hub excels in providing resources to shape your virtual world</p>

  <button className='button text-white rounded-2xl text-[16px] leading-[14px] py-4 px-5  bg-red-900  lg:ml-0  flex m-auto  lg:mt-5'>Begin Journey</button>
</div>
  <motion.div    initial={{ opacity: 0, scaleY: 0.5 }}
        animate={{ opacity: 1, scaleY: 1 }}
        transition={{ duration: 0.6, delay: 0.3 }} className=' lg:w-[600px]  w-[330px] lg:ml-0  m-auto '>
    <img className='lg:w-[600px] lg:h-[444px]    ' src="/homeimage/images1.png" alt="" />
  </motion.div>
</motion.section> 
{/* home section end */}

    </div>
  )
}

export default Navbar;

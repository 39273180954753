import React from 'react'
const Mountin = () => {
  return (
    <div
     className='bg-footer-section  bg-cover bg-center w-full lg:h-[400px] lg:mt-[100px] mt-[50px]'>
  
        <h1 className='heading1 text-center font-bold lg:text-[66px] lg:leading-[66px] text-white lg:mt-[] lg:pt-[100px] text-[50px] leading-[60px]   pt-[50px] '>From the mountains we have arrived</h1>
      
    </div>
  )
}

export default Mountin;

import React from 'react'

const Company = () => {
  return (
    <div className=' lg:mt-[150px] lg:mx-[100px]  mt-[50px]'>
        <main className='lg:flex lg:justify-between lg:items-center'>
           <div> <span style={{ color:"#9A2935"}} className='font-medium lg:text-[18px] lg:leading-[21px] text-center block lg:text-start'>Our portfolio</span>
           <h1 style={{ color:"#9A2935"}} className='text-center lg:text-start my-2 font-bold lg:font-semibold lg:text-[60px] lg:leading-[68px] lg:w-[426px]'>Our Company Portfolio</h1></div>

          <div style={{ backgroundColor:"#9A2935"}}  className='w-[140px] mx-auto lg:mx-0 py-2  lg:text-left  flex gap-2 items-center justify-center lg:w-[194px] lg:h-[53px] rounded-[4px]'> 
          <button className='text-white lg:font-normal lg:text-[18px] lg:[26px]'>All Members </button>
          <h1  className=' h-[21px] w-[21px] bg-white rounded-[100%] text-center'><i className="fa-solid fa-arrow-right"></i> </h1>
          </div>
        </main>
            {/* images cards */}
            <div className='lg:flex  lg:justify-center lg:items-center  lg:mt-[100px] mt-4 '>
                <img  className=' hidden lg:block lg:relative lg:left-[680px] lg:top-[-400px]' src="/services-images/about-shape2.png-removebg-preview.png" alt="" />
               <div style={{ backgroundColor:"#9A2935"}}  className='card1 lg:w-[419px] lg:h-[329px] rounded-[14px] w-[350px] h-[270px]  mx-auto lg:mx-0'> <img
               className='py-[10px] px-[10px]' src="/portfolioimages/image 30.png" alt="" /></div>

               <div style={{ backgroundColor:"#9A2935"}}  className='card2 lg:w-[450px] lg:h-[350px] rounded-[14px] 
               w-[350px] h-[270px] mx-auto lg:mx-0 lg:relative lg:top-[50px] mb-3 lg:mb-0 '> <img
               className='w-[433px] lg:w-[450px] lg:h-[340px] lg:mt-1  m-auto mt-3 py-[10px] px-[10px] h-[270px] ' src="/portfolioimages/image 29.png" alt="" /></div>

               <div style={{ backgroundColor:"#9A2935"}}  className='card3 lg:w-[419px] lg:h-[329px] rounded-[14px]
               w-[350px] h-[270px] mx-auto lg:mx-0 '> <img
               className='py-[10px] px-[10px]' src="/portfolioimages/image 30.png" alt="" /></div>
                
            </div>
            <img className='sm:block hidden lg:block lg:relative lg:left-[1155px] lg:mt-[43px]' src="/portfolioimages/about-shape.png-removebg-preview.png" alt="" />
      
    </div>
  )
}

export default Company;

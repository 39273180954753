import React from 'react'

const Faqs = () => {
  return (
    <div>
      <div className='mt-10 lg:mt-[110px] lg:ml-[110px]'>
        <div className='lg:w-[254px] lg:h-[40px] w-[200px] h-[30px] mx-auto lg:mx-0 rounded-[35px] border-2 border-gray-600 '><h6 className='text-center mt-1'>digital agency Queries</h6>
        </div>

        <h1 className='font-bold lg:text-[50px] lg:leading-[60px] text-[40px] leading-[40px] lg:text-start text-center lg:w-[700px] w-[400px] mt-5'>Frequently Asked Questions</h1>

      </div>

      <main className='flex flex-wrap justify-center items-center lg:gap-[166px] lg:mt-10'>
        <div className=' lg:w-[782px] lg:h-[400px] bg-[#F1F1F1] lg:mx-0 lg:mt-0 rounded-[30px] h-[350px] w-[350px] mx-auto mt-7  '>




            <div className='lg:pt-[40px] lg:pl-[18px] '>
                <h1 className='font-bold lg:text-[26px] lg:leading-[31px] lg:text-start lg:pt-0 text-center pt-5 '>How Much Do Your Services Cost?</h1>
            <p className='font-medium lg:text-[16px] lg:leading-[26px] lg:w-[646px] lg:text-start  pt-5 text-center'>Phasellus condimentum accumsan nunc. Pellentesque habitant morbi tristique
senectus et netus et malesuada fames ac turpis egestas. Aliquam luctus eleifend
vestibulum. Fusce lacinia finibus dui id tristique.</p>
            </div>
            <div className='lg:mx-5 lg:mt-5'>
                <select  className='lg:w-[745px] lg:h-[60px] w-[300px] bg-slate-400 rounded-[5px] mx-5 lg:mx-0 py-2  '  >
                   <option value="" className='lg:text-3xl'> How Long Does SEO </option>
                </select>
                <select  className='lg:w-[745px] lg:h-[60px] w-[300px] bg-slate-400 rounded-[5px] mx-5 lg:mx-0 mt-2 py-2'  >
                   <option value="" className='lg:text-3xl'> Do You Provide Advertising </option>
                </select>
                <select  className='lg:w-[745px] lg:h-[60px] w-[300px] bg-slate-400 rounded-[5px] mx-5 lg:mx-0 mt-2 py-2'  >
                   <option value="" className='lg:text-3xl'>Are You Offering Services </option>
                </select>
                
            </div>
           

        </div>
        <div>
                <img className='lg:h-[400px]  w-[350px] lg:w-[400px] mt-10 lg:mt-0 ' src="/faqs/faqsimg1.png" alt="" />
            </div>
      </main>
    </div>
  )
}

export default Faqs;


import React from 'react'

const Services = () => {
  return (
   <section>
       <div  className='lg:ml-[100px] lg:mt-[100px]'> <span style={{ color:"#9A2935"}} className=' font-medium lg:text-[18px] lg:leading-[21px] text-center block lg:text-start'>Our portfolio</span>
       <h1 style={{ color:"#9A2935"}} className='text-center lg:text-start my-2 font-bold lg:font-semibold lg:text-[60px] lg:leading-[68px] lg:w-[500px]'>What we can offer</h1>
       <img className='' src="/services-images/about-shape2.png-removebg-preview.png" alt="" /></div>

       {/* services cards is here */}
       <main className='flex flex-wrap justify-center gap-14 lg:mt-[30px] lg:mb-[50px]' >
        <div style={{}} className='lg:w-[400px] lg:h-[420px] w-[400px] mx-auto lg:mx-0 h-[370px] rounded-[40px] border-[2px] '>
            <div className='flex justify-center items-center gap-[100px] pt-[30px] '>
            <h3 className='font-bold text lg:text-[26px] lg:leading-[28px]'>Web Development </h3><h1  className=' h-[40px] w-[40px] bg-red-900 rounded-[100%] text-center rotate-[-40deg]'><i className="fa-solid fa-arrow-right text-white mt-[6px] text-xl"></i> </h1>
            </div>

            <div>
                <p className='font-medium lg:text-[16px] lg:leading-[25px] text-gray-600 pt-[50px] pb-[30px] text-center'>totam rem aperiam, eaque ipsa quae ab illo
                inventore et quasi architecto beatae.</p>
                <img className='mx-auto h-[156px]' src="/services-images/Container(4).png" alt="" />
            </div>
        </div>

         <div style={{}} className='w-[400px] mx-auto lg:mx-0 h-[370px] lg:w-[400px] lg:h-[420px] rounded-[40px] border-[2px] '>
            <div className='flex justify-center items-center gap-[100px] pt-[30px] '>
            <h3 className='font-bold text lg:text-[26px] lg:leading-[28px]'>Web Development </h3><h1  className=' h-[40px] w-[40px] bg-red-900 rounded-[100%] text-center rotate-[-40deg]'><i className="fa-solid fa-arrow-right text-white mt-[6px] text-xl"></i> </h1>
            </div>
            <div>
                <p className='font-medium lg:text-[16px] lg:leading-[25px] text-gray-600 pt-[50px] pb-[30px] text-center'>totam rem aperiam, eaque ipsa quae ab illo
                inventore et quasi architecto beatae.</p>
                <img className='mx-auto h-[156px]' src="/services-images/Container(5).png" alt="" />
            </div>
        </div> 

        <div style={{}} className='w-[400px] mx-auto lg:mx-0 h-[370px] lg:w-[400px] lg:h-[420px] rounded-[40px] border-[2px] '>
            <div className='flex justify-center items-center gap-[100px] pt-[30px] '>
            <h3 className='font-bold text lg:text-[26px] lg:leading-[28px]'>Web Development </h3><h1  className=' h-[40px] w-[40px] bg-red-900 rounded-[100%] text-center rotate-[-40deg]'><i className="fa-solid fa-arrow-right text-white mt-[6px] text-xl"></i> </h1>
            </div>
            <div>
                <p className='font-medium lg:text-[16px] lg:leading-[25px] text-gray-600 pt-[50px] pb-[30px] text-center'>totam rem aperiam, eaque ipsa quae ab illo
                inventore et quasi architecto beatae.</p>
                <img className='mx-auto h-[156px]' src="/services-images/Container(6).png" alt="" />
            </div>
        </div> 

     
       </main>
         {/* second services cards  is here */}
         <main className='flex flex-wrap justify-center gap-14 lg:mt-[30px] lg:mb-[50px]' >
        <div style={{}} className='lg:w-[400px] lg:h-[420px] w-[400px] mx-auto lg:mx-0 h-[370px] rounded-[40px] border-[2px]  mt-3'>
            <div className='flex justify-center items-center gap-[100px] pt-[30px] '>
            <h3 className='font-bold text lg:text-[26px] lg:leading-[28px]'>Web Development </h3><h1  className=' h-[40px] w-[40px] bg-red-900 rounded-[100%] text-center rotate-[-40deg]'><i className="fa-solid fa-arrow-right text-white mt-[6px] text-xl"></i> </h1>
            </div>
            <div>
                <p className='font-medium lg:text-[16px] lg:leading-[25px] text-gray-600 pt-[50px] pb-[30px] text-center'>totam rem aperiam, eaque ipsa quae ab illo
                inventore et quasi architecto beatae.</p>
                <img className='mx-auto h-[156px]' src="/services-images/Container(7).png" alt="" />
            </div>
        </div>

         <div style={{}} className='w-[400px] mx-auto lg:mx-0 h-[370px] lg:w-[400px] lg:h-[420px] rounded-[40px] border-[2px] '>
            <div className='flex justify-center items-center gap-[100px] pt-[30px] '>
            <h3 className='font-bold text lg:text-[26px] lg:leading-[28px]'>Web Development </h3><h1  className=' h-[40px] w-[40px] bg-red-900 rounded-[100%] text-center rotate-[-40deg]'><i className="fa-solid fa-arrow-right text-white mt-[6px] text-xl"></i> </h1>
            </div>
            <div>
                <p className='font-medium lg:text-[16px] lg:leading-[25px] text-gray-600 pt-[50px] pb-[30px] text-center'>totam rem aperiam, eaque ipsa quae ab illo
                inventore et quasi architecto beatae.</p>
                <img className='mx-auto rounded-3xl h-[156px]' src="/services-images/Container(8).png" alt="" />
            </div>
        </div> 

        <div style={{}} className='w-[400px] mx-auto lg:mx-0 h-[370px] lg:w-[400px] lg:h-[420px] rounded-[40px] border-[2px] '>
            <div className='flex justify-center items-center gap-[100px] pt-[30px] '>
            <h3 className='font-bold text lg:text-[26px] lg:leading-[28px]'>Web Development </h3><h1  className=' h-[40px] w-[40px] bg-red-900 rounded-[100%] text-center rotate-[-40deg]'><i className="fa-solid fa-arrow-right text-white mt-[6px] text-xl"></i> </h1>
            </div>
            <div>
                <p className='font-medium lg:text-[16px] lg:leading-[25px] text-gray-600 pt-[50px] pb-[30px] text-center'>totam rem aperiam, eaque ipsa quae ab illo
                inventore et quasi architecto beatae.</p>
                <img className='mx-auto rounded-3xl h-[156px]' src="/services-images/Container(9).png" alt="" />
            </div>
        </div> 

     
       </main>
   </section>
  )
}

export default Services;

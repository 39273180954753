import React from 'react'

const Choose = () => {
  return (
    <div className=' mt-5 lg:mt-[200px]'>
        <div  className='lg:ml-[100px] lg:mt-[100px]'> <span style={{ color:"#9A2935"}} className=' font-medium lg:text-[18px] lg:leading-[21px] text-center block lg:text-start'>Our portfolio</span>
       <h1 style={{ color:"#9A2935"}} className='text-center lg:text-start my-2 font-bold lg:font-semibold lg:text-[60px] lg:leading-[68px] lg:w-[500px]'>Why choose us?</h1>
       <img className='' src="/services-images/about-shape2.png-removebg-preview.png" alt="" /></div>
      
      {/* cards */}
      <main className='lg:flex lg:flex-wrap lg:justify-center lg:items-center lg:gap-[50px] '>
        <div className='lg:w-[400px] lg:h-[280px]  rounded-[40px] bg-[#F0F7FF] w-[380px] h-[250px] mx-auto lg:mx-0'>
            <i className="fa-regular fa-lightbulb text-[50px] lg:pl-[40px] lg:pt-[40px] lg:block pt-[30px] flex justify-center  " ></i>
            <h6 className='font-bold text-[26px] lg:leading-[28px] text-[#641B33] lg:pl-[40px] pt-[26px] text-center lg:text-start'>Innovation</h6>
       <p className='lg:pl-[40px] pt-[20px] text-[#A8A8A9] lg:w-[380px] text-center lg:text-start px-3 lg:px-0'>Sed ut perspiciatis unde omnis iste natus
       error sit voluptatem accusantium.</p>
        </div>

        <div className='lg:w-[400px] lg:h-[280px]  rounded-[40px] bg-[#F0F7FF] w-[380px] h-[250px] mx-auto lg:mx-0 mt-5 lg:mt-0'>
            <i className="fa-solid fa-users-rays text-[50px] lg:pl-[40px] lg:pt-[40px] lg:block pt-[40px] flex justify-center  " ></i>
            <h6 className='font-bold text-[26px] lg:leading-[28px] text-[#641B33] lg:pl-[40px] pt-[26px] text-center lg:text-start'>Quality-Focused</h6>
       <p className='lg:pl-[40px] pt-[20px] text-[#A8A8A9] lg:w-[380px] text-center lg:text-start px-3 lg:px-0'>Sed ut perspiciatis unde omnis iste natus
       error sit voluptatem accusantium.</p>
        </div>

        <div className='lg:w-[400px] lg:h-[280px]  rounded-[40px] bg-[#F0F7FF] w-[380px] h-[250px] mx-auto lg:mx-0 mt-5 lg:mt-0'>
            <i className="fa-solid fa-money-bill-wheat text-[50px] lg:pl-[40px] lg:pt-[40px] lg:block pt-[40px] flex justify-center  " ></i>
            <h6 className='font-bold text-[26px] lg:leading-[28px] text-[#641B33] lg:pl-[40px] pt-[26px] text-center lg:text-start'>Value For Money</h6>
       <p className='lg:pl-[40px] pt-[20px] text-[#A8A8A9] lg:w-[380px] text-center lg:text-start px-3 lg:px-0'>Sed ut perspiciatis unde omnis iste natus
       error sit voluptatem accusantium.</p>
        </div>
      </main>

      {/* large cards here */}  
      <div className='lg:w-[1200px] w-[400px] mx-auto mt-8 lg:h-[450px] h-[480px] bg-[#F0F7FF] rounded-[40px]'>

       <div className='lg:flex lg:pl-[40px] pt-[30px] lg:pt-[60px] lg:gap-[120px] flex justify-center items-center'> 
        <h1 className=' lg:font-bold lg:text-[70px] text-[20px] lg:leading-[76px] text-[#641B33] lg:w-[804px] w-[300px]  '> Do you want to explore
        our outstanding work?</h1>
        <i className="fa-solid fa-globe lg:text-[63px] text-[40px] text-blue-600 pt-4" ></i></div>
        <div className='lg:flex block lg:items-center lg:justify-center gap-12 mt-[60px]'>
            <p className='font-medium text-[16px] leading-[34px] text-[#9C9FA6] lg:w-[600px] px-5 lg:px-0 '>Sed ut perspiciatis unde omnis iste natus error sit voluptatem
accusantium , totam rem aperiam, eaque ipsa quae ab illo inventore
et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
ipsam voluptatem quia voluptas sit.</p>

<div className='flex flex-wrap gap-3 mt-7  px-3'>
    <div className='lg:w-[150px] h-[40px]  lg:h-[50px] bg-white rounded-[30px] flex justify-center gap-[30px] items-center'>
        <h6 className='lg:font-bold lg:text-[16px] lg:leading-[19px] leding-[15px] text-[12px]  ml-1 lg:ml-0'>Dribbble</h6>
        <i className="fa-solid fa-arrow-right rotate-[-40deg] mr-1 lg:mr-0" ></i>
    </div>
    <div className='lg:w-[150px] lg:h-[50px] h-[40px] bg-white rounded-[30px] flex justify-center gap-[30px] items-center'>
        <h6 className='lg:font-bold lg:text-[16px] lg:leading-[19px] leding-[15px] text-[12px]  ml-1 lg:ml-0'>linkedin</h6>
        <i className="fa-solid fa-arrow-right rotate-[-40deg] mr-1 lg:mr-0" ></i>
    </div>
    <div className='lg:w-[150px] lg:h-[50px] h-[40px] bg-white rounded-[30px] flex justify-center gap-[30px] items-center'>
        <h6 className='lg:font-bold lg:text-[16px] lg:leading-[19px] leding-[15px] text-[12px] ml-1 lg:ml-0'>contact Us</h6>
        <i className="fa-solid fa-arrow-right rotate-[-40deg] mr-1 lg:mr-0" ></i>
    </div>
</div>
        </div>
        
      </div>

      {/* faqs cards are here */}

     
    </div>
  )
}

export default Choose;

import React from 'react'

const About = () => {
  return (
    <section className='section bg-[#F3F7FD] lg:flex lg:items-center block items-center '>
<main className='lg:ml-[20px]  '>
 <div className='flex gap-[30px] lg:ml-[100px] ml-[50px] pt-5 mt-5 lg:mt-0 lg:pt-0 '> 
      <div className='box lg:w-[120px] lg:h-[200px] rounded-[10px] w-[100px] h-[150px] '>
<div>     
       <h6 className='circle lg:w-[117px] lg:h-[136px] w-[100px] h-[100px] rounded-[80px] lg:relative lg:left-[2px] lg:top '> </h6>
<div className='flex items-center gap-2 justify-center pt-[20px]'>
    <h1 className='font-bold text-[57px] leading-[67px] text-white relative top-[-121px] '>18</h1>
    <span className='font-bold text-[56px] leading-[26px] text-white relative top-[-121px]'>+</span> 
</div>
</div>
    </div>
    <img src="/aboutimages/aboutimg2.png" alt=""  className='w-[262px] h-[260px]'/>
    <img className='relative lg:left-[-400px] lg:top-[230px] lg:w-[327px] lg:h-[350px] top-[180px] left-[-400px] h-[300px] '  src="/aboutimages/aboutimg1.png" alt="" />
    </div>
 
    
</main>

{/* main second is here */}
<main className='block items-center justify-center lg:mt-5 mt-[200px]   '>
    <h1 className='text-amber-950 font-bold lg:text-[42px]   lg:leading-[40px] text-[45px] text-center lg:text-start'>About Us</h1>
    <p className='font-medium lg:text-[18px] leading-[26px] text-gray-700 lg:w-[400px] lg:mt-[40px] text-center lg:text-start '>Monotonectally synergize granular markets and front markets. Collaboratively visualize
strategic infomediaries after multimedia based models. Synergistically task state of the
art infrastructures for</p>
<main className='ml-[100px] lg:ml-0'>
<div className='flex gap-8 items-center mt-12 justify-items-center'>
<i className="fa-solid fa-money-bill text-3xl text-blue-600"> </i>
<h6 className='font-semibold lg:text-[22px] lg:leading-[26px]'>100% Money Back Gaurentee</h6>
</div>
<div className='flex gap-8 items-center mt-12'><i className="fa-solid fa-phone text-3xl text-blue-600" ></i>
<h6 className='font-semibold lg:text-[22px] lg:leading-[26px]'>    24/7 Free Tehcnical Supports</h6></div>
<div className='flex gap-8 items-center mt-12'>
    <i className="fa-solid fa-user-plus text-3xl text-blue-600" ></i>
    <h6 className='font-semibold lg:text-[22px] lg:leading-[26px]'>Expert & Dedicated Team
    Members</h6>
</div>
<div className='flex gap-8 items-center mt-12'>
    <i className="fa-solid fa-person-military-pointing text-3xl text-blue-600" ></i>
    <h6 className='font-semibold lg:text-[22px] lg:leading-[26px]'>
    100% Customers
    Satisfaction
    </h6>
</div>
</main>

</main>

    </section>
  )
}

export default About;

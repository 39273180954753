import React, { useState } from "react";
import { motion } from "framer-motion";

const Testimonials = () => {
  const images = [
    "/testimonials/WhatsApp Image 2025-01-09 at 14.23.29_8e4dc809.png",
    "/testimonials/WhatsApp Image 2025-01-09 at 14.23.29_f0894ba9.png",
    "/testimonials/WhatsApp Image 2025-01-09 at 14.23.29_f219adc5.png",
    "/testimonials/WhatsApp Image 2025-01-09 at 14.23.30_3634a108.png",
    "/testimonials/WhatsApp Image 2025-01-09 at 14.23.30_3ad2f9e5.png",
    "/testimonials/WhatsApp Image 2025-01-09 at 14.23.30_3ce3bdc4.png",
    "/testimonials/WhatsApp Image 2025-01-09 at 14.23.31_26d90839.png",
    "/testimonials/WhatsApp Image 2025-01-09 at 14.50.47_113247c3.png",
  ];

  const [xOffset, setXOffset] = useState(0);

  // Move images left
  const handleNext = () => {
    setXOffset((prev) => prev - 200); // Adjust movement
  };

  // Move images right
  const handlePrev = () => {
    setXOffset((prev) => prev + 200); // Adjust movement
  };

  return (
    <div className="mt-[100px] lg:mx-16 mx-5">
      {/* Header */}
      <h6 className="text-center font-semibold text-[18px] text-[#9A2935]">
        Testimonial
      </h6>
      <h1 className="font-bold text-center mt-5 text-[30px] lg:text-[42px] w-full lg:w-[600px] mx-auto text-[#641B33] leading-[40px]">
        Client’s Awesome Feedback About Our Services
      </h1>

      {/* Images Section */}
      <div className="lg:pt-10 flex justify-center items-center gap-5 mt-10 overflow-hidden">
        {/* Left Button */}
        <button
          onClick={handlePrev}
          className="px-4 py-2 bg-blue-500 text-white rounded-[50%] shadow-md"
        >
          <i className="fa-solid fa-arrow-left"></i>
        </button>

        {/* Image Slider */}
        <div className="w-full max-w-1xl overflow-hidden">
          <motion.div
            className="flex space-x-4"
            animate={{ x: xOffset }}
            transition={{ ease: "easeOut", duration: 0.5 }}
          >
            {images.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Testimonial ${index}`}
                className="w-40 h-40 md:w-48 md:h-48 rounded-lg shadow-md"
              />
            ))}
          </motion.div>
        </div>

        {/* Right Button */}
        <button
          onClick={handleNext}
          className="px-4 py-2 bg-blue-500 rounded-[50%] text-white shadow-md"
        >
          <i className="fa-solid fa-arrow-right"></i>
        </button>
      </div>
      <div>
        <p className="font-normal lg:text-[20px] lg:leading-[34px] leading-[20px]  text-center lg:w-[800px] lg:mx-auto lg:mt-16 mt-8">Completely extend leveraged customer service rather than performance based imperatives.
magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
technology. Enthusiastically e-enable global e-markets for cooperative e-business.
Authoritatively deliver highly efficient expertise</p>
      </div>
    </div>
  );
};

export default Testimonials;


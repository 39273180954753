import React from 'react'
import Navbar from './compopnents/Navbar'
import Company from './compopnents/Company'
import Mountain from './compopnents/Mountin'
import Services from './compopnents/Services'
import About from './compopnents/About'
import Choose from './compopnents/Choose'
import Faqs from './compopnents/Faqs'
import Testimonials from './compopnents/Testimonials'
import Footer from './compopnents/Footer'
const App = () => {
  return (
    <div>
       <Navbar/>
       <Company/>
       <Mountain/>
       <Services/>
       <About/>
       <Choose/>
       <Faqs/>
       <Testimonials/>
       <Footer/>
      
    </div>
  )
}

export default App
